<template>
  <div class="main">
    <headlogin></headlogin>
    <div class="content">
      <div class="cons">
        <div class="login-title">
          <div class="login-title-wz">注册账号</div>
        </div>
        <div class="login-con">
          <el-form
            :label-position="'top'"
            label-width="200px"
            :model="formLabelAlign"
            :rules="baseRules"
            ref="ruleFormRef"
          >
            <el-form-item label="用户名" required prop="userName">
              <el-input
                v-model="formLabelAlign.userName"
                placeholder="请输入您的用户名"
                 @keyup.enter="onSubmit(ruleFormRef)" 
              />
            </el-form-item>
            <el-form-item label="密码" required prop="password">
              <el-input
                type="password"
                v-model="formLabelAlign.password"
                show-password
                placeholder="请设置密码"
                 @keyup.enter="onSubmit(ruleFormRef)" 
              />
            </el-form-item>
            <!-- <el-form-item label="验证码" required prop="code">
              <el-input
                v-model="formLabelAlign.code"
                placeholder="请输入短信验证码"
              >
                <template #append>
                  <el-button>获取验证码</el-button>
                </template>
              </el-input>
            </el-form-item> -->
            <el-form-item>
              <el-button type="primary" @click="onSubmit(ruleFormRef)"
                >立即注册</el-button
              >
              <el-button @click="onzc">登录系统</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

let formLabelAlign = reactive({
  userName: "",
  password: "",
  nickname:'test',
  sex:1,
  // code: "",
});
const ruleFormRef = ref();
const baseRules = {
  userName: [{ required: true, message: "用户名不能为空", trigger: "blur" }],
  password: [{ required: true, message: "密码不能为空", trigger: "blur" }],
  // code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
};

const onSubmit = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    // 通过验证规则 true
    if (valid) {
      post("/member/reg", formLabelAlign).then((res) => {
        ElMessage({
          type:'success',
          message:'注册成功',
          duration: 1000,
            onClose: () => {
              router.push({
                path: "/login",
                query: {},
              });
            },
        })
      });
    }
  });
};

// 登录
const onzc = () => {
  router.push({
    path: "/login",
    query: {},
  });
};
</script>

<style scoped>
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 600px;
  margin: auto;
  height: 500px;
  padding: 30px 0;
}
.login-title {
  border-bottom: 1px solid #666;
  padding: 20px 0;
  margin-bottom: 30px;
}
.login-title-wz {
  font-size: 24px;
  text-align: center;
}
</style>
